#root  div {
  position: relative;
}
.transition-group {
  position: relative;
}

.moveLeft-enter {
  transform: translate(200%);
}

.moveLeft-enter.moveLeft-enter-active {
  transform: translate(0%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
}

.moveLeft-exit {
  position: absolute !important;
  top: 0;
  width: 100%;
  transform: translateX(0%);
}

.moveLeft-exit.moveLeft-exit-active {
  transform: translateX(-200%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
}

.moveRight-enter {
  transform: translate(-200%);
}

.moveRight-enter.moveRight-enter-active {
  transform: translate(0%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
}

.moveRight-exit {
  position: absolute !important;
  top: 0;
  width: 100%;
  transform: translate(0%);
}

.moveRight-exit.moveRight-exit-active {
  transform: translate(200%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
}

.moveUp-enter {
  transform: translateY(200%);
  z-index: -200;
}

.moveUp-enter.moveUp-enter-active {
  transform: translateY(0%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
  z-index: -200;
}

.moveUp-exit {
  position: absolute !important;
  top: 0;
  width: 100%;
  transform: translateY(0%);
  z-index: -200;
}

.moveUp-exit.moveUp-exit-active {
  transform: translateY(-200%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
  z-index: -200;
}

.moveDown-enter {
  transform: translateY(-200%);
  z-index: -200;
}

.moveDown-enter.moveDown-enter-active {
  transform: translateY(0%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
  z-index: -200;
}

.moveDown-exit {
  position: absolute !important;
  top: 0;
  width: 100%;
  transform: translateY(0%);
  z-index: -200;
}

.moveDown-exit.moveDown-exit-active {
  transform: translateY(200%);
  -webkit-transition: transform 1000ms ease-in-out;
  transition: transform 1000ms ease-in-out;
  z-index: -200;
}
