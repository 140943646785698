ol.bs-wizard {
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin-bottom: 0px;
}

.bs-wizard li {
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.7rem;
  //border-top: 8px solid #f5f5f5;
}

.bs-wizard li.todo i:before {
  color: #f5f5f5;
}

.bs-wizard li.doing i:before {
  color: #0071bb;
}

.bs-wizard li.done i:before {
  color: #008540;
}

.bs-wizard li.done {
  border-top-color: #008540;
}

.bs-wizard li i {
  font-size: 2rem;
  margin-top: -6px;
  z-index: 20;
}

.bs-wizard li .bar {
  display: block;
  width: 100%;
  height: 0.5rem;
  margin-bottom: -16px;
  background-color: #f5f5f5;
}

.bs-wizard li:first-child .bar {
  width: 60%;
  margin-left: 40%;
}

.bs-wizard li:last-child .bar {
  width: 60%;
  margin-right: 40%;
}

.bs-wizard-text {
  max-width: 85px;
  line-height: 1rem;
}

.bs-wizard-text-sm {
  margin-top: -32px;
}

.bs-wizard li.doing .bs-wizard-text-sm,
.bs-wizard li.done .bs-wizard-text-sm {
  color: #fff;
}

.bar-overlay {
  background-color: #008540;
  height: 100%;
  width: 0%;
}

.bs-wizard li.doing .bar-overlay {
  width: 50%;
}

.bs-wizard li:last-child.doing .bar-overlay {
  width: 100%;
}

.bs-wizard li.doing:first-child .bar-overlay {
  width: 0%;
}

.bs-wizard li.done .bar-overlay {
  width: 100%;
}

.bs-wizard li i {
  background-color: #fff;
  border-radius: 50%;
}

.bs-wizard li i:before {
  content: "\f111";
}

.bs-wizard li.done i:before {
  content: "\f058";
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box div {
  width: 100px;
  height: 100px;
}

#root {
  overflow-x: hidden;
}

.pull-right-lg {
  float: none;
  display: block;
}

@media only screen and (min-width: 768px) {
  .pull-right-lg {
    float: right;
  }
}

.no-margin-btm {
  margin-bottom: 0 !important;
}

.small-green-bold {
  color: green;
  font-weight: bold;
}

.magic-radio {
  position: relative;
  display: none;
}

.magic-radio[disabled] {
  cursor: not-allowed;
}

.magic-radio+label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
  margin-right: 1.5rem;
  vertical-align: middle;
}

.magic-radio+label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio+label:before {
  position: absolute;
  top: -5px;
  left: -5px;
  display: inline-block;
  width: 30px;
  height: 30px;
  content: '';
  border: 1px solid #c0c0c0;
}

.magic-radio+label:after {
  position: absolute;
  display: none;
  content: '';
}

.magic-radio[disabled]+label {
  cursor: not-allowed;
  color: #e4e4e4;
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after {
  cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}

.magic-radio[disabled]+label:before {
  border-color: #e4e4e4;
}

.magic-radio:checked+label:before {
  animation-name: none;
}

.magic-radio:checked+label:after {
  display: block;
}

.magic-radio+label:before {
  border-radius: 50%;
}

.magic-radio+label:after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #008540;
}

.magic-radio:checked+label:before {
  border: 1px solid #008540;
}

.magic-radio:checked[disabled]+label:before {
  border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled]+label:after {
  background: #c9e2f9;
}

.magic-radio.smaller+label:before {
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.magic-radio.smaller+label:after {
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
}