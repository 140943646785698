.card-title {
  font-size: 25px;
  color: #e17509;
}

.review-font {
  font-size: 16px;
}

.margin-bottom-neg-15 {
  margin-bottom: -15px;
}

.margin-bottom-neg-5 {
  margin-bottom: -5px;
}

.cursor-pointer {
  cursor: pointer;
}
